<template>
    <v-layout wrap>
      <v-snackbar v-model="showSnackBar" color="black">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#FDAC53"
        spinner="bar-fade-scale" />
      <v-layout wrap>
        <v-flex xs12 text-left>
          <span class="itemHeading">Promo Code</span>
        </v-flex>
        <v-flex xs12 text-right>
          <v-btn class="mx-2" fab dark color="blue" @click="dialog2 = true">
            <v-icon dark> mdi-plus </v-icon> </v-btn><span style="
              font-family: opensansregular;
              font-size: 18px;
              color: black;
              letter-spacing: 1px;
              cursor: pointer;
            ">Add Promo Code</span></v-flex>
        <v-flex pa-4 xs6 v-for="(item, i) in list" :key="i">
          <v-card class="pa-4">
            <v-layout wrap>
              <v-flex xs12>
                <div class="grad text-center">
                  <span class="headline" style="font-size: 18px; color: #000000">{{ item.code }}</span>
                </div>
              </v-flex>
              <v-divider></v-divider>
              <v-flex xs6 py-5>
                <span style="font-size: 16px; font-family: poppinsmedium;">Change Promo Code Status</span>
              </v-flex>
              <v-divider></v-divider>
              <v-flex xs6 pt-3 text-right>
                <v-btn v-if="item.status == 'Active'" x-small dark @click="ChangePromocodeStatus(item._id)" color="green">
                  Active</v-btn>
                <v-btn v-if="item.status == 'Inactive'" x-small dark @click="ChangePromocodeStatus(item._id)" color="error">
                  Inactive</v-btn>
              </v-flex>
              <v-flex pt-2 xs12 text-center>
                <span style="font-family: poppinsmedium">Offer Validity</span> <br />
                <span style="font-size: 13px;font-family: poppinsregular;">{{
                  formatDate(item.fromDate)
                }}</span>&nbsp;to
                <span style="font-size: 13px; font-family: poppinsregular;">{{
                  formatDate(item.toDate)
                }}</span>
              </v-flex>
              <v-flex xs12><v-divider></v-divider></v-flex>
              <v-flex xs6 pt-3>
                <span style="font-size: 15px; color: #000000;font-family: poppinsmedium;">Name<br />
                  <span style="font-size: 15px; font-family: poppinsregular;">
                    {{ item.name }}</span></span>
              </v-flex>
              <v-flex xs6 pt-3>
                <span style="font-size: 15px; color: #000000;font-family: poppinsmedium;">Description<br />
                  <span style="font-size: 15px; font-family: poppinsregular;">
                    {{ item.description }}</span></span>
              </v-flex>
              <v-flex xs6 pt-3>
                <span style="font-size: 15px; color: #000000;font-family: poppinsmedium;">No of Promocodes <br />
                  <span style="font-size: 15px" class="opensansregular">
                    {{ item.maxToBeApplied }}</span></span>
              </v-flex>
              <v-flex xs6 pt-3>
                <span style="font-size: 15px; color: #000000;font-family: poppinsmedium;">
                  Promocodes Remaining<br />
                  <span style="font-size: 15px" class="opensansregular">
                    {{ item.limitRemainig }}</span></span>
              </v-flex>
              <v-flex xs6 pt-3>
                <span style="font-size: 15px; color: #000000;font-family: poppinsmedium;">
                  Minimum Quality<br />
                  <span style="font-size: 15px" class="opensansregular">
                    {{ item.minQuantity }}</span></span>
              </v-flex>
              <v-flex xs6 pt-3>
                <span style="font-size: 15px; color: #000000;font-family: poppinsmedium;">
                  Maximum Quality<br />
                  <span style="font-size: 15px" class="opensansregular">
                    {{ item.maxQuantity }}</span></span>
              </v-flex>
              <v-flex xs12 py-3>
                <span style="font-size: 15px; color: #000000;font-family: poppinsmedium;">
                  Discount Percentage<br />
                  <span style="font-size: 15px" class="opensansregular">
                    {{ item.discount }}%</span></span>
              </v-flex>
  
              <!-- <v-flex xs6 pa-2>  <v-btn
                      x
                      small
                      class="mr-1"
                      outlined
                      block
                      @click="(dialog1 = true,curid = item)"
                      color="blue"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn></v-flex> -->
              <v-flex xs12>
                <v-layout wrap>
                  <v-flex xs6 pa-2>
                    <center>
                      <v-btn small dark class="mr-2" @click="(deletedialog = true), (curid = item._id)"
                        color="red"><span>Delete</span>
                      </v-btn>
                    </center>
                  </v-flex>
                  <v-flex xs6 pa-2>
                    <center>
                      <v-btn small dark class="mr-1"
                        @click="(dialog1 = true, curid = item, curid.fromDate = item.fromDate.slice(0, 10), curid.toDate = item.toDate.slice(0, 10))"
                        color="blue"><span>Edit</span>
                      </v-btn>
                    </center>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-layout wrap v-if="list.length < 0">
          <v-flex align-self-center pt-5>
            <span class="emptyField">No Promocode Available!!</span>
          </v-flex>
        </v-layout>
        <v-flex xs12 pt-4>
          <v-divider></v-divider>
          <v-dialog v-model="dialog2" max-width="800px">
            <v-card class="grad">
              <v-layout wrap justify-center>
                <v-flex xs12><v-layout wrap justify-center pa-5>
                    <v-flex xs8 pa-4 text-center>
                      <span class="opensansregular" style="font-size: 20px;">
                        <strong>Add Promo Code</strong></span>
                    </v-flex>
                    <v-layout wrap pt-5>
  
                      <v-flex xs12 sm6 pa-2 class="opensansregular">
                        <span>Code</span>
                        <v-form @submit.prevent="validateInput">
                          <v-text-field color="black" placeholder=" Code" outlined background-color="white" dense
                            type="text" v-model="code" hide-details>
                          </v-text-field>
                        </v-form>
                      </v-flex>
  
                      <v-flex xs12 sm6 pa-2 class="opensansregular">
                        <span>Name</span>
                        <v-form @submit.prevent="validateInput">
                          <v-text-field color="black" placeholder="Name" outlined background-color="white" dense type="text"
                            v-model="name" hide-details>
                          </v-text-field>
                        </v-form>
                      </v-flex>
                      <v-flex xs12 sm6 pa-2 class="opensansregular">
                        <span>Discount Percentage</span>
                        <v-form @submit.prevent="validateInput">
                          <v-text-field color="black" placeholder="Discount Percentage" outlined background-color="white"
                            dense type="number" v-model="discount" hide-details>
                          </v-text-field>
                        </v-form>
                      </v-flex>
                      <v-flex xs12 sm6 pa-2 class="opensansregular">
                        <span>No of Promocodes</span>
                        <v-form @submit.prevent="validateInput">
                          <v-text-field color="black" placeholder="No of Promocodes" outlined background-color="white" dense
                            type="number" v-model="limt" hide-details>
                          </v-text-field>
                        </v-form>
                      </v-flex>
                      <v-flex xs12 sm12 pa-2 class="opensansregular">
                        <span>Description</span>
                        <v-form @submit.prevent="validateInput">
                          <v-textarea color="black" placeholder="Description" outlined background-color="white" dense
                            type="text" v-model="description" hide-details>
                          </v-textarea>
                        </v-form>
                      </v-flex>
  
                      <v-flex xs12 sm6 pa-2 class="opensansregular">
                        <span>From Date</span>
                        <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" max-width="290">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field clearable placeholder="From Date" readonly outlined dense v-model="fromDate"
                              v-bind="attrs" hide-details v-on="on" @click:clear="from = null"></v-text-field>
                          </template>
                          <v-date-picker v-model="fromDate" @change="menu1 = false"></v-date-picker>
                        </v-menu>
                      </v-flex>
  
                      <v-flex xs12 sm6 pa-2 class="opensansregular">
                        <span> To Date</span>
                        <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" max-width="290">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field clearable placeholder="To Date" readonly outlined dense v-model="toDate"
                              v-bind="attrs" v-on="on" hide-details @click:clear="from = null"></v-text-field>
                          </template>
                          <v-date-picker v-model="toDate" @change="menu2 = false"></v-date-picker>
                        </v-menu>
                      </v-flex>
                      <v-flex xs12 sm6 pa-2 class="opensansregular">
                        <span>Minimum Quality</span>
                        <v-form @submit.prevent="validateInput">
                          <v-text-field color="black" placeholder="Minimum Quality" outlined background-color="white" dense
                            type="number" v-model="minQuantity" hide-details>
                          </v-text-field>
                        </v-form>
                      </v-flex>
                      <v-flex xs12 sm6 pa-2 class="opensansregular">
                        <span>Maximum Quality</span>
                        <v-form @submit.prevent="validateInput">
                          <v-text-field color="black" placeholder="Maximum Quality" outlined background-color="white" dense
                            type="number" v-model="maxQuantity" hide-details>
                          </v-text-field>
                        </v-form>
                      </v-flex>
                    </v-layout>
                    <v-flex xs12 sm6 text-center pa-4>
                      <v-btn dark color="#68D389" @click="add()"><span class="opensanssemibold">ADD</span></v-btn>
                    </v-flex>
                    <v-flex xs12 sm6 text-center pa-4>
                      <v-btn dark color="red" @click="dialog2 = false"><span class="opensanssemibold">Cancel</span></v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-dialog>
          <v-dialog v-model="deletedialog" max-width="600px">
            <v-card>
              <v-card-title>Are you sure you want to delete?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="deletedialog = false">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItem()">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialog1" max-width="800px">
            <v-card>
              <v-layout wrap justify-center class="grad" pa-5>
                <v-flex pt-10><v-layout wrap justify-center>
                    <v-flex xs8 pa-4 text-center>
                      <span class="opensansregular" style="font-size:20px"> <strong>Edit Promocode</strong></span>
                    </v-flex>
                    <v-layout wrap>
  
                      <v-flex xs12 sm6 pa-2 class="opensansregular">
                        <span>Code</span>
                        <v-form @submit.prevent="validateInput">
                          <v-text-field color="black" placeholder="Code" outlined background-color="white" dense
                            v-model="curid.code" hide-details>
                          </v-text-field>
                        </v-form>
                      </v-flex>
  
                      <v-flex xs12 sm6 pa-2 class="opensansregular">
                        <span>Name</span>
                        <v-form @submit.prevent="validateInput">
                          <v-text-field color="black" placeholder="Name" outlined background-color="white" dense
                            v-model="curid.name" hide-details>
                          </v-text-field>
                        </v-form>
                      </v-flex>
                      <v-flex xs12 sm12 pa-2 class="opensansregular">
                        <span>Description</span>
                        <v-form @submit.prevent="validateInput">
                          <v-textarea color="black" placeholder="Description" outlined background-color="white" dense
                            v-model="curid.description" hide-details>
                          </v-textarea>
                        </v-form>
                      </v-flex>
                      <v-flex xs12 sm6 pa-2 class="opensansregular">
                        <span>No of Promocodes</span>
                        <v-form @submit.prevent="validateInput">
                          <v-text-field color="black" placeholder="Promocodes Remaining" outlined background-color="white"
                            dense v-model="curid.maxToBeApplied" hide-details>
                          </v-text-field>
                        </v-form>
                      </v-flex>
                      <!-- <v-flex xs12 sm6 pa-2 class="opensansregular">
                      <span>Promocodes Remaining</span>
                      <v-form @submit.prevent="validateInput">
                        <v-text-field
                          color="black"
                          placeholder="Promocodes Remaining"
                          outlined
                          background-color="white"
                          dense
                          v-model="curid.limitRemainig"
                          hide-details
                        >
                        </v-text-field>
                      </v-form>
                    </v-flex> -->
                      <v-flex xs12 sm6 pa-2 class="opensansregular">
                        <span>Discount Percentage</span>
                        <v-form @submit.prevent="validateInput">
                          <v-text-field color="black" placeholder="Discount Percentage" outlined background-color="white"
                            dense type="number" v-model="curid.discount" hide-details>
                          </v-text-field>
                        </v-form>
                      </v-flex>
                      <v-flex xs12 sm6 pa-2 class="opensansregular">
                        <span>From Date</span>
                        <v-menu ref="menu3" v-model="menu3" :close-on-content-click="false" max-width="290">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field clearable placeholder="To Date" readonly outlined dense v-model="curid.fromDate"
                              v-bind="attrs" v-on="on" @click:clear="from = null"></v-text-field>
                          </template>
                          <v-date-picker v-model="curid.fromDate" @change="menu3 = false"></v-date-picker>
                        </v-menu>
                      </v-flex>
                      <v-flex xs12 sm6 pa-2 class="opensansregular">
                        <span>To Date</span>
                        <v-menu ref="menu4" v-model="menu4" :close-on-content-click="false" max-width="290">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field clearable placeholder="To Date" readonly outlined dense v-model="curid.toDate"
                              v-bind="attrs" v-on="on" @click:clear="from = null"></v-text-field>
                          </template>
                          <v-date-picker v-model="curid.toDate" @change="menu4 = false"></v-date-picker>
                        </v-menu>
                      </v-flex>
                      <v-flex xs12 sm6 pa-2 class="opensansregular">
                        <span>Minimum Quality</span>
                        <v-form @submit.prevent="validateInput">
                          <v-text-field color="black" placeholder="Minimum Quality" outlined background-color="white" dense
                            v-model="curid.minQuantity" hide-details>
                          </v-text-field>
                        </v-form>
                      </v-flex>
                      <v-flex xs12 sm6 pa-2 class="opensansregular">
                        <span>Maximum Quality</span>
                        <v-form @submit.prevent="validateInput">
                          <v-text-field color="black" placeholder="Maximum Quality" outlined background-color="white" dense
                            v-model="curid.maxQuantity" hide-details>
                          </v-text-field>
                        </v-form>
                      </v-flex>
                    </v-layout>
                    <v-flex xs12 sm6 text-center pa-4>
                      <v-btn color="#68D389" dark @click="edit()"><span class="opensanssemibold">SUBMIT</span></v-btn>
                    </v-flex>
                    <v-flex xs12 sm6 text-center pa-4>
                      <v-btn color="red" dark @click="dialog1 = false"><span class="opensanssemibold">Cancel</span></v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-dialog>
        </v-flex>
        <v-layout wrap>
          <v-flex xs12>
            <v-pagination prev-icon="mdi-menu-left" next-icon="mdi-menu-right" :length="pages" :total-visible="7"
              v-model="currentpage" color="green"></v-pagination>
          </v-flex>
        </v-layout>
      </v-layout>
    </v-layout>
  </template>
     
  <script>
  import axios from "axios";
  export default {
    data() {
      return {
        menu: false,
        limit: null,
        link: null,
        page: 1,
        currentpage: 1,
        code: null,
        menu1: false,
        menu2: false,
        menu3: false,
        menu4: false,
        discount: null,
        fromDate: null,
        toDate: null,
        minQuantity: 1,
        maxQuantity: 100,
        url: null,
        name: null,
        limt: null,
        deletedialog: false,
        promo: false,
        appLoading: false,
        description: null,
        array: [],
        msg: null,
        showSnackBar: false,
        duration: null,
        pages: 0,
        list: [],
        curid: [],
        userId: null,
        dialog1: false,
        noOfDays: null,
        offerPrice: null,
        amount: null,
        dialog2: false,
      };
    },
    mounted() {
      this.getlist();
      //   this.getplan();
      // this.getlink();
    },
  
    methods: {
      //   getplan() {
      //     this.appLoading = true;
      //     axios({
      //       method: "POST",
      //       url: "/admin/active/users/list",
      //       headers: {
      //         'x-auth-token': localStorage.getItem("token"),
      //       },
      //     })
      //       .then((response) => {
      //         this.array = response.data.data;
      //         this.appLoading = false;
      //       })
  
      //       .catch((err) => {
      //         this.ServerError = true;
      //         console.log(err);
      //       });
      //   },
      deleteItem() {
        axios({
          url: "/delete/promocode",
          method: "POST",
          data: {
            id: this.curid,
          },
          headers: {
            'x-auth-token': localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status == true) {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              (this.deletedialog = false), this.getlist();
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      add() {
        axios({
          method: "POST",
          url: "/admin/add/promoCode",
          headers: {
            'x-auth-token': localStorage.getItem("token"),
          },
          data: {
            code: this.code,
            name: this.name,
            description: this.description,
            fromDate: this.fromDate,
            toDate: this.toDate,
            limt: this.limt,
            discount: this.discount,
            maxQuantity: this.maxQuantity,
            minQuantity: this.minQuantity
          },
        })
          .then((response) => {
            this.listz = response.data.data;
            if (response.data.status == true) {
              this.dialog2 = false;
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.getlist();
              this.code = "",
                this.name = "",
                this.description = "",
                this.fromDate = "",
                this.toDate = "",
                this.limt = "",
                this.discount = "",
                this.maxQuantity = "",
                this.minQuantity = ""
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            this.ServerError = true;
            console.log(err);
          });
      },
  
      formatDate(item) {
        var dt = new Date(item);
        var year = dt.getFullYear();
        dt = dt.toString();
        var strTime = dt.slice(4, 10) + " " + year;
        return strTime;
      },
      edit() {
        axios({
          method: "POST",
          url: "/admin/edit/promoCode",
          headers: {
            'x-auth-token': localStorage.getItem("token"),
          },
          data: {
            id: this.curid._id,
            code: this.curid.code,
            name: this.curid.name,
            description: this.curid.description,
            discount: this.curid.discount,
            fromDate: this.curid.fromDate,
            toDate: this.curid.toDate,
            limt: this.curid.limitRemainig,
            maxQuantity: this.curid.maxQuantity,
            minQuantity: this.curid.minQuantity
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              this.dialog1 = false;
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.getlist();
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            this.ServerError = true;
            console.log(err);
          });
      },
      ChangePromocodeStatus(value) {
        this.appLoading = true;
        axios({
          url: "/change/status/promocode",
          method: "POST",
          headers: {
            'x-auth-token': localStorage.getItem("token"),
          },
          data: {
            id: value,
          },
        })
          .then((response) => {
            if (response.data.status) {
              this.getlist();
            } else {
              this.showSnackbar = true;
              this.msg = response.data.msg;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      getlist() {
        this.appLoading = true;
        axios({
          method: "GET",
          url: "/admin/promocode/list",
          data: {
            page: this.currentpage,
            limit: this.limit,
          },
          headers: {
            'x-auth-token': localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.list = response.data.data;
            this.appLoading = false;
          })
  
          .catch((err) => {
            this.ServerError = true;
            console.log(err);
          });
      },
    },
  };
  </script>